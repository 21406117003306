
.leaflet-sidebar-content::-webkit-scrollbar {
    width: 0px;
  }

.leaflet-sidebar-content.night-theme {
    background-color: rgb(52 51 50);
    color: #cccccc;
}

.leaflet-sidebar-tabs.night-theme {
    background-color: rgb(52 51 50);
}

.leaflet-tooltip {
    white-space: normal;
    border-radius: 8px;
}

/* Leaflet geosearch control */

.leaflet-control-geosearch form {
    padding: 0 18px;
}
.leaflet-touch .leaflet-control-geosearch a.reset {
    font-size: 30px;
    font-weight: normal;
    padding: 0px;
    margin: 0 18px;
}

.leaflet-control-geosearch form input {
    font-size: 16px;
}

.leaflet-control-geosearch .results > * {
    font-size: 16px;
}

.leaflet-geosearch-bar form {
    border-radius: 18px;
}

/* Leaflet zoom controls */

.leaflet-bar {
    border-radius: 18px;
}

.leaflet-touch .leaflet-bar a:first-child {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}

.leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
}

/* webcam listitem styles */

.webcamlistitem {
    display: flex;
    margin: 6px 0px;
    cursor: pointer;
}

.webcamlistitem-image {
    margin-right: 20px;
}

.webcamlistitem-image img {
    border-radius: 8px;
}

.webcamlistitem-data {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.webcamlistitem-title {
    font-weight: bold;
    font-size: 13px;
}

.webcamlistitem-prediction {
    background-color: #FFFF66;
    border: 1px solid #FFFF00;
    border-radius: 10px;
    font-size: 10px;
    max-width: 50px;
    color: darkslategrey;
}

.webcamlistitem-predictionconfidence {
    font-weight: 100;
    font-size: 10px;
}

.webcamlistitem-createdat {
    font-weight: 100;
    font-size: 10px;
}

.webcamlistitem-country {
    font-weight: 100;
    font-size: 10px;
}


/* webcam tool tip styles */

.webcammarkertooltip {
    /*
    display: flex;
    flex-direction: column;
    */
    width: 203px;
    height: 203px;
    cursor: pointer;
}

.webcammarkertooltip-image {
}

.webcammarkertooltip-image img {
    border-radius: 8px;
}

.webcammarkertooltip-data {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.webcammarkertooltip-title {
    font-weight: bold;
    font-size: 13px;
}

.webcammarkertooltip-prediction {
    background-color: #FFFF66;
    border: 1px solid #FFFF00;
    border-radius: 10px;
    font-size: 10px;
    max-width: 50px;
    color: darkslategrey;
}

.webcammarkertooltip-predictionconfidence {
    font-weight: 100;
    font-size: 10px;
}

.webcammarkertooltip-createdat {
    font-weight: 100;
    font-size: 10px;
}

.webcammarkertooltip-country {
    font-weight: 100;
    font-size: 10px;
}

/* styles for the icons on the map and the list */

.prediction-icon {
    margin-right: 4px;
}

.map-pin-icon {
    color: red;
    text-shadow: 0px 1px 3px #000000;
}

.map-webcam-icon-inactive .map-webcam-icon-inactive.night-theme {
    color: lemonchiffon;
    font-size: 15px;
    text-shadow: 0px 1px 3px #000000;
}

.map-webcam-icon-active {
    color: #cbf83e;
    font-size: 15px;
    text-shadow: 0px 2px 4px #000000;
}

.map-webcam-icon-active.night-theme {
    color: #999;
    font-size: 15px;
    text-shadow: 0 1px 1px #FFFF00;
}

.map-webcam-icon-moon {
    color: #FFFF00;
    font-size: 15px;
    margin: -5px;
}

.map-prediction-icon-sun {
    color: #FFFF00;
    font-size: 20px;
    text-shadow: 2px 2px 4px #000000;
}

.map-prediction-icon-cloud {
    color: #ccc;
    font-size: 20px;
    text-shadow: 2px 2px 4px #000000;
}

.list-webcam-icon-inactive .list-webcam-icon-inactive.night-theme {
    color: lemonchiffon;
    text-shadow: 0px 1px 3px #000000;
}

.list-webcam-icon-active {
    color: #cbf83e;
    text-shadow: 0px 1px 3px #000000;
}

.list-webcam-icon-active.night-theme {
    color: #999;
    text-shadow: 0 1px 1px #FFFF00;
}


.list-prediction-icon-sun {
    color: #FFFF00;
    font-size: 12px;
    text-shadow: 2px 2px 4px #000000;
}

.list-prediction-icon-cloud {
    color: #ccc;
    font-size: 12px;
    text-shadow: 0px 1px 3px #000000;
}


/* leaflet sidebar */
.leaflet-sidebar-header {
    color: #fff;
    background-color: #cbf83e;
}

.leaflet-sidebar-tabs > ul > li.active {
    color: #fff;
    background-color: #cbf83e;
}

/* leaflet control center plugin */

/* control positioning */
.leaflet-center {
    position: relative !important;
    left: 0;
    right: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.leaflet-center .leaflet-control
{
    bottom: 0;
}

.leaflet-control-container .leaflet-control-bottomcenter {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

/* styles for the Leaflet webcam filter control */

.webcam-filter-control {
    background-color: white;
    border-radius: 18px;
    padding: 2px;
    color: #343332;
}

.webcam-filter-prediction-icon-sun {
    color: #FFFF00;
    font-size: 20px;
    text-shadow: 2px 2px 4px #000000;
}

.webcam-filter-prediction-icon-cloud {
    color: #ccc;
    font-size: 20px;
    text-shadow: 0px 1px 3px #000000;
}

.webcam-filter-chkbox {
    height: 16px;
    width: 16px;
    margin-left: 10px;
}

.webcam-filter-chkbox-label {
    font-size: 16px;
}

/* styles for cluster markers */
.marker-cluster-small {
    background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
    background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
    background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
    background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
    background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
    background-color: rgba(241, 128, 23, 0.6);
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}
.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
    line-height: 30px;
}

